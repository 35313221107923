/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-weight: 400;
  position: pixed;
  background-color: #121212 !important;
  background-image: url('./assets/img/banner-bg1.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  z-index: -1;
  color: #49a6ff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 5px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
.navbar-collapse{
  width: 100%;
  border-radius: 20px;
}
nav.navbar a.navbar-brand {
    width: 5%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #49a6ff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 1;
}

.navbar-link{
  margin:0;
  padding:0;
  font-size:120px;
  text-transform:uppercase;
  position:relative;
  color:#ccc;
}

.navbar-link:hover{
  color:#cccccc4f;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #49a6ff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #49a6ff;
  border: 1px solid #49a6ff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #49a6ff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text img:hover {
  top: 50%;
  opacity: 1;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #49a6ff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #49a6ff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #49a6ff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #49a6ff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #49a6ff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner HOME Css ************/
.bannerHome {
  margin-top: 20%;
}

@media screen and (max-width:600px)  {
	.bannerHome {
		margin-top: 80%;
    margin-bottom: 30%;
	}
  .bannerHome img {
    margin-top: -1100px;
    padding:  40px 40px 40px 40px;
    /*animation: updown 3s linear infinite;*/
  }
}


.bannerHome .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.bannerHome h1 {
  font-size: 60px;
  font-weight: 700;
  margin-top: 30px;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
}

.bannerHome p {
  margin-top: 0px;
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.bannerHome button {
  color: #49a6ff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 0px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.bannerHome button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.bannerHome button:hover svg {
  margin-left: 25px;
}

.bannerImg {
  margin-top: -100px;
  /*animation: updown 3s linear infinite;*/
}

/************ Banner Team Css ************/
.bannerTeam {
  background-color: #00000096;
  border-radius: 50px;
  margin-top: 15%;
  margin-bottom: 0;
  margin-left: 20%;
  margin-right: 20%;
}
@media screen and (max-width:600px)  {
	.bannerTeam {
		margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 30%;
	}
}

.BannerTeamItem {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 40px;
}

.BannerTeamItemImage{ 
  text-align: center;
}

.BannerTeamItemImage img{
  text-align: center;
  height: 200px;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 100px;
}

.BannerTeamsocial-icon {
  display: inline-block;
  margin-left: 14px;
}
.BannerTeamsocial-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.BannerTeamsocial-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #49a6ff;
  border-radius: 50%;
  transform: scale(0);
transition: 0.3s ease-in-out;
}
.BannerTeamsocial-icon a:hover::before {
  transform: scale(1);
}
.BannerTeamsocial-icon a img {
width: 40%;
z-index: 1;
transition: 0.3s ease-in-out;
}
.BannerTeamsocial-icon a:hover img {
filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

/************ Banner Streamers Css ************/
.bannerStreamers {
  background-color: #00000096;
  border-radius: 50px;
  margin-top: 15%;
  margin-bottom: 0;
  margin-left: 20%;
  margin-right: 20%;
}


.BannerStreamersItem {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 40px;
}

.BannerStreamersItemImage{ 
  text-align: center;
}

.BannerStreamersItemImage img{
  text-align: center;
  height: 200px;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 100px;
}

.BannerStreamerssocial-icon {
  display: inline-block;
  margin-left: 14px;
}
.BannerStreamerssocial-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.BannerStreamerssocial-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #49a6ff;
  border-radius: 50%;
  transform: scale(0);
transition: 0.3s ease-in-out;
}
.BannerStreamerssocial-icon a:hover::before {
  transform: scale(1);
}
.BannerStreamerssocial-icon a img {
width: 40%;
z-index: 1;
transition: 0.3s ease-in-out;
}
.BannerStreamerssocial-icon a:hover img {
filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

@media screen and (max-width:600px)  {
	.BannerStreamers {
		margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 30%;
	}
  .BannerStreamersItemImage img{
    margin-bottom: 0px;
  }
}

/************ Banner Gallery Css ************/
.bannerGallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  margin-top: 4%;
  padding: 0 12px;
}

.bannerGallery .bannerGallerypics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.bannerGallerypicsImg {
  border-radius: 50px;
}

.bannerGalleryContainer h1{
  margin-top: 7%;
  
  text-align: center;
}

.bannerGalleryContainer {
  padding-bottom: 10%;
}

.bannerGallery .bannerGallerypics:hover {
  filter: opacity(.8);
}

/************ Banner Pack Auto Css ************/
.bannerPackAuto {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  margin-top: 4%;
  padding: 0 12px;
}

.bannerPackAutoimgClose{
  position: fixed;
  cursor: pointer;
}

@media screen and (max-width:600px)  {
  .bannerPackAutoimgClose{
    margin: auto;

    top: 90%;
    cursor: pointer;
  }
}

@media screen and (min-width:600px)  {
  .bannerPackAutoimgClose{
    margin: auto;
    right: 5%;
    cursor: pointer;
  }
}

.bannerPackAuto .bannerPackAutopics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.bannerPackAutopicsImg {
  border-radius: 50px;
}

.bannerPackAutoContainer h1{
  margin-top: 7%;
  
  text-align: center;
}

.bannerPackAutoContainer {
  height: 100%;
  width: 100%;
}

.bannerPackAuto .bannerPackAuto:hover {
  filter: opacity(.8);
}

/************ Banner Pack Auto Model Css ************/


.bannerPackAutomodel {
  width: 80%;
  height: 70%;
  position: fixed;
  top: 10%;
  left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000e1;
  border-radius: 50px;
  transition: opacity .4s ease, visibility .4 ease, transform .5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

@media screen and (max-width:600px)  {
	.bannerPackAutomodel {
    top: 20%;
    left: 5%;
    width: 90%;
    height: 50%;
	}
}

.bannerPackAutomodel.open{
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.bannerPackAutomodel img{
  border-radius: 70px;
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  margin: 0 auto;
}

.bannerPackAutoItem {
  text-align: center;
}

.bannerPackAutoItemImage{ 
  text-align: center;
}

.bannerPackAutoItemImage img{
  text-align: center;
  border-radius: 30px;
}



/************ Model Css ************/

.model {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000093;
  transition: opacity .4s ease, visibility .4 ease, transform .5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open{
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.imgClose{
  position: fixed;
  right: 4%;
  width: 20;
  height: 20;
  cursor: pointer;
}

.model img{
  border-radius: 70px;
  height: auto;
  width: auto;
  max-width: 80%;
  max-height: 80%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

@media (max-width: 480px){
  .bannerGallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
  .bannerPackAuto {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
  .imgClose{
    position: fixed;
    right: 4%;
    width: 20;
    height: 20;
    cursor: pointer;
  }

  .bannerGalleryContainer {
    padding-bottom: 30%;
  }

  .bannerPackAutoContainer {
    padding-bottom: 30%;
  }
  
  .model img{
    border-radius: 50px;
  }
}

/************ Banner Css ************/
.banner {
  margin-top: 20%;
}

@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
  color: #646464;
}



/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}
.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #49a6ff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #49a6ff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #49a6ff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #49a6ff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #49a6ff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/

.footer {
  position: fixed;
  margin: 0 20% 0 20%;
  width: 60%;
  bottom: 10px;
  z-index: 9999;
}

@media screen and (max-width:450px)  {
	.footer {
		margin: 0 5% 0 5%;
    width: 90%;
	}
}

.footer-bx {
  background: #00000096;
  border-radius: 25px;
  text-align: center;
  padding: 15px 0px;
  margin-top: 0px;
}
.footer h2 {
	font-size: 45px;
}
.footer p {
    color: #000000;
    letter-spacing: 0px;
}
.footer-slider {
  width: 100%;
  margin:  auto;
  position: absolute;
}
.footer-slider .item img {
  width: 10%;
  margin: 0 auto 15px auto;
}

.footer h5 {
  font-size: 15px;
}
.footer p {
  font-weight: 10;
  font-size: 14px;
  color: #49a6ff;
  letter-spacing: 0.5px;
}

/************ END Footer Css ************/



.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

.newsletter-bx {
  background: #49a6ff;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #49a6ff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #49a6ff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #49a6ff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
